import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import "./App.css";
import axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";

import SceneComponent from "./SceneComponent";

function App() {
  const [file, setFile] = useState(null);
  const [ModalMessage, setModalMessage] = useState(
    "This feature will be added soon! Stay tuned for exciting updates."
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [CompressedFile, setCompressedFile] = useState(null);
  const [LoadingDisplay, setLoadingDisplay] = useState(false);
  const [compressionLevel, setCompressionLevel] = useState(1);
  const [extPostCompression, setextPostCompression] = useState("glb");
  const fileInputRef = useRef();
  const [SceneDisplay, setSceneDisplay] = useState("none");
  const [UploadDisplay, setUploadDisplay] = useState("");
  const [fileName, setFileName] = useState("");
  const [pos, setPos] = useState({ x: 500, y: 0 });
  const handleChange = (selectedfile) => {
    setFileName(selectedfile[0].name.split(".")[0]);
    if (!selectedfile.length) {
      console.log("No file selected.");
      return;
    }
    setUploadDisplay("none");
    setSceneDisplay("");
    const file = selectedfile[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    const allowedExtensions = ["glb", "obj", "fbx", "gltf"];
    if (!allowedExtensions.includes(fileExtension)) {
      // alert('Unsupported file type. Please select a GLB, OBJ, FBX, or GLTF file.');
      setModalMessage(
        "Unsupported file type. Please select a GLB, OBJ, FBX, or GLTF file."
      );
      openModal();
      return;
    }
    setFile(selectedfile[0]);
  };
  const mainRef = useRef(null);
  const [leftWidth, setLeftWidth] = useState(50);

  const handleDrag = (e, ui) => {
    setPos({ x: ui.x, y: ui.y });
    const newLeftWidth =
      leftWidth + (ui.deltaX * 100) / mainRef.current.offsetWidth;
    if (newLeftWidth < 0 || newLeftWidth > 100) return;
    setLeftWidth(newLeftWidth);
    console.log(newLeftWidth, "newLeftWidth");
    // setPos(ui.deltaX, 0);
  };
  const clickFileInput = () => {
    fileInputRef.current.click();
  };
  const CompressAction = async () => {
    if (file == null) {
      // alert('add file to compress');
      setModalMessage("Please add file to compress.");
      openModal();
      setSceneDisplay("none");
      setUploadDisplay("");
      return;
    }
    setLoadingDisplay(true);
    const formData = new FormData();
    formData.append("compressionLevel", compressionLevel);
    formData.append("extPostCompression", extPostCompression);
    formData.append("uploadedFile", file);
    try {
      const response = await axios.post(
        "https://api.modularcx.link/converter-api/upload",
        formData,
        {
          responseType: "blob",
        }
      );
      const compressedFileObject = new File(
        [response.data],
        `modelCompressed.${extPostCompression}`,
        { type: response.data.type }
      );
      setCompressedFile(compressedFileObject);
      console.log("COMPRESSED FILE ", CompressedFile);
      // Create a URL for the response blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log("COMPRESSED FILE ", CompressedFile);
      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = `modelCompressed.${extPostCompression}`;
      // a.click();
      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // console.error('Error:', error);
      setModalMessage(
        "Oops! Something went wrong with your request. Please check your file and try again, or contact support if the problem persists."
      );
      openModal();
    } finally {
      setLoadingDisplay(false);
    }
  };
  const downloadactionfile = () => {
    if (!CompressedFile) {
      console.error("No file to download.");
      return;
    }
    // Create an object URL for the file
    const url = window.URL.createObjectURL(CompressedFile);

    // Create a temporary anchor element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + "-compressed." + extPostCompression; // Use the file name stored in the File object
    document.body.appendChild(a); // Append the anchor to the body
    a.click(); // Simulate a click on the anchor to start the download

    // Clean up by revoking the object URL and removing the anchor element
    window.URL.revokeObjectURL(url);
    a.remove();
  };
  function FileInfoDisplay({ originalFile, compressedFile }) {
    // Convert bytes to megabytes with two decimal places
    const toMB = (bytes) => (bytes / 1024 / 1024).toFixed(2);

    const originalSizeMB = originalFile ? toMB(originalFile.size) : 0;
    const compressedSizeMB = compressedFile ? toMB(compressedFile.size) : 0;
    console.log("sssssssssssssssssssss", compressedSizeMB);
    const sizeReduction = (originalSizeMB - compressedSizeMB).toFixed(2);

    return (
      <div className="rowinf hint">
        <div className="rowtitle hint">Almost {sizeReduction}MB reduced</div>
        <div className="rowvalue hint">{originalSizeMB} MB</div>
      </div>
    );
  }

  // function EstimatedInfoDisplay({ originalFile, compressionLevel }) {

  //   const getRateByCompressionLevel = (level) => {
  //     // Example mapping, adjust according to your requirements
  //     const rateMapping = {
  //       1: 0.78,
  //       2: 0.799,
  //       3: 0.8,
  //       4: 0.85,
  //       5: 0.88,
  //       6: 0.9,
  //       7: 0.91,
  //       8: 0.92,
  //       9: 0.93,
  //       10: 0.94
  //     };

  //     return rateMapping[level] || 0.78; // Default rate if level is not defined
  //   };
  //   // Convert bytes to megabytes with two decimal places
  //   const toMB = (bytes) => (bytes / 1024 / 1024).toFixed(2);

  //   const originalSizeMB = originalFile ? toMB(originalFile.size) : 0;

  //   // Calculate the rate based on the current compression level
  //   const rate = getRateByCompressionLevel(compressionLevel);

  //   const Estimated = (originalSizeMB * rate).toFixed(2);

  //   return (
  //     <div className='rowinf'>
  //       <div className='rowtitle'>Estimated Reduced Size</div>
  //       <div className='rowvalue'>{Estimated}MB</div>
  //     </div>
  //   );
  // }
  function EstimatedInfoDisplay({ compressedFile }) {
    if (!compressedFile) {
      console.log("No compressed file provided.");
      return null;
    }

    const toMB = (bytes) => (bytes / 1024 / 1024).toFixed(2);
    const compressedSizeMB = toMB(compressedFile.size);

    return (
      <div className="rowinf">
        <div className="rowtitle">
          Estimated <br />
          Reduced Size
        </div>
        <div className="rowvalue">{compressedSizeMB}MB</div>
      </div>
    );
  }

  const UploadAnotherFile = () => {
    setFile(null);
    setCompressedFile(null);
    setUploadDisplay("");
    setSceneDisplay("none");
    setLoadingDisplay(false);
  };
  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "20px",
    zIndex: 1000,
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
  };

  const backdropStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  };
  const FeatureAlert = () => {
    setModalMessage(
      "This feature will be added soon! Stay tuned for exciting updates."
    );
    openModal();
  };
  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  function Modal({ isOpen, message, onClose }) {
    if (!isOpen) return null;

    return (
      <>
        <div style={backdropStyles} onClick={onClose} />
        <div style={modalStyles}>
          <div className="Mcontent">
            <p>{message}</p>
            <button
              onClick={onClose}
              style={{
                position: "absolute",
                top: "-50px",
                right: "-50px",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: "#1E4B94",
                color: "#FFF",
              }}
            >
              X
            </button>
          </div>
        </div>
      </>
    );
  }

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false); // Reset drag over state
    const files = e.dataTransfer.files; // Access the files
    if (files.length) {
      handleChange(files); // Assuming handleChange can handle the file(s) correctly
    }
  };

  return (
    <div className="App">
      <Modal isOpen={isModalOpen} onClose={closeModal} message={ModalMessage} />
      <div className="AppContainer">
        <div className="AppHeader">
          <div className="NavContainer">
            {/* <div className='Navsec'>
              <img src='more.png' alt='' />
              <button style={{ padding: "0" }} onClick={FeatureAlert}>Tools</button>
            </div>
            <div className='Navsec'>
              <button onClick={FeatureAlert}>pricing</button>
            </div>
            <div className='Navsec'>
              <button onClick={FeatureAlert}>Guide center</button>
            </div> */}
          </div>
          <div className="LogoContainer">
            <img src="M_Lock_up_1x6rev (1).png" alt="" />
          </div>
          <div className="LScontainer">
            {/* <button onClick={FeatureAlert} className='Login-btn'>Login </button>
            <button onClick={FeatureAlert} className='SignUp-btn'>Sign up </button> */}
          </div>
        </div>
        <div className="AppBody">
          <div className="ControlDIV">
            <div className="TitleControl">Compression Level</div>
            <div className="Slider-Container">
              <div className="SliderDIV">
                <div className="LevelsContainer">
                  <span>Low</span>
                  <span>Medium</span>
                  <span>High</span>
                </div>
                <input
                  type="range"
                  className="CusRange"
                  min={1}
                  max={10}
                  value={compressionLevel}
                  onChange={(e) => setCompressionLevel(e.target.value)}
                />
              </div>
            </div>
            <div className="InformationContainer">
              <div className="infocontent">
                {/* {file && <EstimatedInfoDisplay originalFile={file} compressionLevel={compressionLevel} />} */}
                {CompressedFile && (
                  <EstimatedInfoDisplay compressedFile={CompressedFile} />
                )}

                {CompressedFile && (
                  <FileInfoDisplay
                    originalFile={file}
                    compressedFile={CompressedFile}
                  />
                )}

                <div className="action-btn">
                  <button onClick={() => CompressAction()}>
                    Compress File
                  </button>
                </div>
                {CompressedFile && (
                  <div className="action-btn">
                    <button onClick={downloadactionfile}>
                      Download Compressed File
                    </button>
                  </div>
                )}
                {file && (
                  <div className="action-btn">
                    <button onClick={UploadAnotherFile}>
                      Upload Another File
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="up-Container">
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={(e) => handleChange(e.target.files)}
              accept=".glb,.obj,.fbx,.gltf"
            />
            <div
              className="fileuploaddiv"
              onClick={clickFileInput}
              style={{
                display: UploadDisplay,
                borderColor: isDragOver ? "#1E4B94" : "transparent", // Example to change border color on drag over
                background: isDragOver ? "rgba(30, 75, 148, 0.1)" : "", // Example to change background on drag over
              }}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <p className="uploadPrompt">
                Upload or Drag your 3D file here
                <span>GLB, OBJ, FBX, GLTF</span>
                <img src="down-arrows.png" className="uparrow" alt="" />
              </p>
            </div>

            <div className="fileuploaddiv" style={{ display: SceneDisplay }}>
              {file && (
                <div className="Scontent" ref={mainRef}>
                  <div className="OriginalSceneContainer">
                    <div className="Scenecon">
                      <SceneComponent
                        key={`original-${file ? file.name : "empty"}`}
                        file={file}
                      />
                      <div className="TitleScene">BEFORE</div>
                    </div>
                  </div>
                  <div className="TheBar">
                    {/* <div className='Catcher'>
                      <img className='leftar' src="arrow.png" alt='' draggable="false" />
                      <img className='righttar' src="arrow.png" alt='' draggable="false" />
                    </div> */}
                  </div>
                  <div className="ConvertedSceneContainer">
                    <div className="Scenecon1">
                      <SceneComponent
                        key={`compressed-${
                          CompressedFile ? CompressedFile.name : "empty"
                        }`}
                        file={CompressedFile}
                      />
                      <div className="TitleScene">AFTER</div>
                    </div>
                  </div>
                  {LoadingDisplay ? (
                    <div className="LoadingDIV">
                      Processing
                      <div class="loader"></div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
