// SceneComponent.js
import React, { useEffect, useRef } from "react";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";

const SceneComponent = ({ file }) => {
    const sceneRef = useRef(null);

    useEffect(() => {
        if (file) {
            // Load Babylon.js scene
            const canvas = sceneRef.current;
            const engine = new BABYLON.Engine(canvas, true);
            const scene = new BABYLON.Scene(engine);

            // scene.debugLayer.show();

            scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

            // Camera
            const camera = new BABYLON.ArcRotateCamera(
                "Camera",
                1.6,
                1.5,
                2,
                new BABYLON.Vector3(0, 0, 0),
                scene
            );
            camera.lowerBetaLimit = 0;
            camera.upperBetaLimit = 1.6;
            camera.panningSensibility = 0;
            camera.wheelPrecision = 250;
            camera.pinchPrecision = 2000;
            camera.minZ = 0.01;
            camera.maxZ = 10000000;

            camera.attachControl(canvas, true);

            const sandBoxRadiusFunction = () => {
                //SANDBOX METHOD
                camera.useFramingBehavior = true;

                const framingBehavior = camera.getBehaviorByName("Framing");
                framingBehavior.framingTime = 0;
                framingBehavior.elevationReturnTime = -1;

                if (scene.meshes.length) {
                    camera.lowerRadiusLimit = null;

                    const worldExtends = scene.getWorldExtends(function (mesh) {
                        return mesh.isVisible && mesh.isEnabled();
                    });

                    framingBehavior.zoomOnBoundingInfo(
                        worldExtends.min,
                        worldExtends.max
                    );

                    //I ADDED
                    camera.lowerRadiusLimit = camera.radius / 5;
                    //I ADDED
                    camera.upperRadiusLimit = 5 * camera.radius;
                }
                //SANDBOX METHOD
            };

            // Lights
            const light1 = new BABYLON.HemisphericLight(
                "light1",
                new BABYLON.Vector3(0, 1, 0),
                scene
            );
            const light2 = new BABYLON.PointLight(
                "light2",
                new BABYLON.Vector3(0, 1, -1),
                scene
            );

            // let assetsManager = new BABYLON.AssetsManager(scene);

            // //called when a single task has been sucessfull
            // assetsManager.onTaskSuccessObservable.add(function (task) {
            //   // var mesh = task.loadedMeshes[0]; //will hold the mesh that has been loaded recently
            //   console.log("task successful", task);
            // });

            // assetsManager.onTaskErrorObservable.add(function (task) {
            //   console.log(
            //     "task failed",
            //     task.errorObject.message,
            //     task.errorObject.exception
            //   );
            // });
            var filename = file.name;
            var blob = new Blob([file]);

            BABYLON.FilesInput.FilesToLoad[filename.toLowerCase()] = blob;

            // assetsManager.addMeshTask("name", "", "file:", filename);
            // assetsManager.load();

            BABYLON.SceneLoader.Append("file:", filename, scene, function () {
                var Model = scene.getMeshByName("__root__");
                Model.setEnabled(false);
                camera.setTarget(Model);
                // Model.setEnabled(false);
                // camera.setTarget(Model);

                scene.executeWhenReady(async function () {
                    Model.setEnabled(true);
                    sandBoxRadiusFunction();
                });

                // Render loop
                engine.runRenderLoop(() => {
                    if (scene) {
                        scene.render();
                    }
                });

                // Resize
                window.addEventListener("resize", () => {
                    engine.resize();
                });

                // Clean up when component unmounts
                return () => {
                    engine.dispose();
                };
            });
        }
    }, [file]);

    return <canvas ref={sceneRef} style={{ border: 'none', outline: 'none', width: '100%', height: '100%' }} />;
};

export default SceneComponent;
